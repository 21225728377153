import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'

/**
 * Single page (/:slug)
 *
 * This file renders a single page and loads all the content.
 *
 */
const Page = ({ data, location }) => {
    const page = data.markdownRemark

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="website"
            />
            <Helmet>
                <style type="text/css">{`${page.frontmatter.codeinjection_styles || ``}`}</style>
            </Helmet>
            <Layout>
                <div className="container">
                    <article className="content">
                        { page.frontmatter.feature_image && page.frontmatter.feature_image.childImageSharp ?
                            <figure className="post-feature-image">
                                <Img fluid={page.frontmatter.feature_image.childImageSharp.fluid}
                                    alt={ page.frontmatter.title } style={{ marginBottom: `1vw` }}/>
                            </figure> : null }
                        <h1 className="content-title">{page.title}</h1>

                        {/* The main page content */}
                        <section
                            className="content-body load-external-scripts"
                            dangerouslySetInnerHTML={{ __html: page.html }}
                        />
                    </article>
                </div>
            </Layout>
        </>
    )
}

Page.propTypes = {
    // TODO: check proptype
    data: PropTypes.object.isRequired,
    // data: PropTypes.shape({
    //     ghostPage: PropTypes.shape({
    //         codeinjection_styles: PropTypes.object,
    //         title: PropTypes.string.isRequired,
    //         html: PropTypes.string.isRequired,
    //         feature_image: PropTypes.string,
    //     }).isRequired,
    // }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Page

// export const postQuery = graphql`
//     query($slug: String!) {
//         ghostPage(slug: { eq: $slug }) {
//             ...GhostPageFields
//         }
//     }
// `

export const postQuery = graphql`
    query($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                feature_image {
                    childImageSharp {
                        fluid(maxWidth: 1000, maxHeight: 500) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                title
            }
        }
    }
`
